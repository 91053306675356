import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'

import initI18n from './services/i18n'
import { store } from './services/redux'

import { BRAND_NAME, PRODUCT } from './config'

const ConnectedApp = React.lazy(() => {
  return import('./App')
})

const getStyles = () => {
  return import('./styles.scss')
}

const PositionRestore = () => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = useState(location.pathname)

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname)
      if (location.hash && location.hash !== '') {
        setTimeout(() => {
          const id = location.hash.replace('#', '')
          const element = document.getElementById(id)
          if (element) element.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }, [location, prevLocation])

  return null
}

function Init() {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    Promise.all([getStyles(BRAND_NAME), initI18n(BRAND_NAME, PRODUCT)]).then(
      () => {
        setLoaded(true)
      }
    )
  }, [])
  return loaded ? (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <Router>
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  const root = createRoot(rootElement)
  root.render(<Init />)
}

try {
  window.jQuery.fn.jquery = ''
} catch (error) {
  console.warn('error hiding verions')
}

try {
  window._.VERSION = ''
} catch (error) {
  console.warn('error hiding verions')
}

render()
